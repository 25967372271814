<template>
  <page-header-wrapper style="margin: unset;">

    <a-form-model ref='form' :model='form' layout='inline'>
       <a-form-model-item prop='start'>
        <a-range-picker
          v-model="dateArr"
          :format="[formatType, formatType]"
          :show-time="{
            hideDisabledOptions: true
          }"
          :disabled-date="disabledDate"
          @change="dateChange"
        />
      </a-form-model-item>
      <a-form-model-item prop='orderId'>
        <a-input v-model='form.orderId' style="width: 300px" placeholder="订单号" />
      </a-form-model-item>

      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
        <template>
          <span style="margin-left: 10px;margin-right: 10px;"> {{this.showOutTypeText()}}：{{ext1}}</span>
        </template>
      </div>

      <s-table id="table" ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: 1500}' :data='loadData'>
        <span slot='memberType' slot-scope='text'>
          <template>
            {{ text == "store" ? "3" : text == "center" ? "2" : text == "president" ? "1" :  "" }}
          </template>
        </span>
        <span slot='businessContent' slot-scope='text, record'>
          <template>
            {{ (record.afterBalance - record.beforeBalance) | parseMoney }}
          </template>
        </span>
        <span slot='businessContent' slot-scope='text, record'>
          <template>
            {{ (record.afterBalance - record.beforeBalance) | parseMoney }}
          </template>
        </span>
        <span slot='memberName' slot-scope='text, record'>
          <template>
             {{text?text:record.memberMobile}}
          </template>
        </span>
      </s-table>
    </div>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis, ChartCard } from '@/components'
import { getAuditOrderData } from '@/api/member-order'
import moment from 'moment'
import { getUserTypeName } from '@/utils/util'
import {queryInsideStorehouseProdList} from "@/api/inside";


const columns = [
  {
    width: 150,
    title: '订单号',
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    width: 150,
    title: '购买人',
    dataIndex: 'memberName',
    scopedSlots: { customRender: 'memberName' }
  },
  {
    width: 120,
    title: '商户负责人',
    dataIndex: 'merchantMasterName',
    scopedSlots: { customRender: 'merchantMasterName' }
  },
  {
    width: 200,
    title: '商品名称',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' }
  },
  {
    width: 100,
    title: '数量',
    dataIndex: 'storageCount',
    scopedSlots: { customRender: 'storageCount' }
  },
  {
    width: 200,
    title: '备注',
    dataIndex: 'remark',
    scopedSlots: { customRender: 'remark' }
  },
  {
    width: 150,
    title: '时间',
    dataIndex: 'storageTim',
    scopedSlots: { customRender: 'storageTim' }
  },
  {
    width: 100,
    title: '操作人',
    dataIndex: 'opUsername',
    scopedSlots: { customRender: 'opUsername' }
  },
]

const formatType = 'YYYY-MM-DD';
const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().format(formatType);

export default {
  name: 'AuditCashList',
  components: {
    STable, Ellipsis, ChartCard
  },
  data() {
    return {
      // 筛选表单
      formatType,
      dateArr: [baseStart, baseEnd],
      form: {
        orderId: "",
        start: moment(baseStart).startOf('day').format(formatType + " HH:mm"),
        end: moment(baseEnd).endOf('day').format(formatType+ " HH:mm"),
      },
      loading: true,
      dashboard: {}, //订单统计数据
      ext1:0,
      // 表头
      columns,
      type:2,
      // 加载数据方法 必须为 Promise 对象
      queryParam: {},
      loadData: parameter => {
        parameter.pageNum = parameter.current;
        parameter.pageSize = parameter.size;
        parameter.type=this.type;
        parameter.isDefault=1;
        let param = Object.assign(parameter, this.queryParam, this.form);
        // debugger;
        return queryInsideStorehouseProdList(param)
          .then(datum => {
            debugger;
            this.ext1 = datum.ext1;
            return datum
          })
      },
    }
  },
  created() {
    // debugger;
    this.type = this.$route.query.type
    this.handleSubmit(this.type);
  },
  activated() {
    // debugger;
    this.type = this.$route.query.type
    this.handleSubmit(this.type);
  },
  methods: {
    showText(record){
      let text = "";
      if(record.recordType===2|| record.recordType === 9){
        text = record.opUsername;
      }else if(record.recordType === 6){
        text = record.transferFromUserName+"转";
      }else if(record.recordType === 5){
        text = record.transferToUserName+"得";
      }else{
        text = record.orderId;
      }
      return text;
    },
    moment,
    getUserTypeName,
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    dateChange() {
      this.form.start =  moment(this.dateArr[0]).startOf('day').format(this.formatType + " HH:mm");
      this.form.end =  moment(this.dateArr[1]).endOf('day').format(this.formatType+ " HH:mm");
    },
    handleReset() {
      this.dateArr = [baseStart, baseEnd];
      // this.form.start = moment(baseStart).startOf('day').format(this.formatType + " HH:mm");
      // this.form.end = moment(baseEnd).endOf('day').format(this.formatType + " HH:mm");
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleSubmit() {
      // this.getOrderData();
      if(this.$refs.table){
        this.$refs.table.refresh(true)
      }

    },
    getOrderData() {
      getAuditOrderData(Object.assign({}, this.form)).then(result => {
        result.wealthQuotaAmount = result.wealthQuotaAmount || 0,
        result.wealthZxQuotaAmount = result.wealthZxQuotaAmount || 0,
        result.wealthBtcfQuotaAmount = result.wealthBtcfQuotaAmount || 0,
        result.totalQuotaAmount = result.wealthQuotaAmount + result.wealthZxQuotaAmount + result.wealthBtcfQuotaAmount;
        result.totalQuotaAmount = parseFloat(result.totalQuotaAmount.toFixed(2));
        result.storeCash = result.storeCash || 0,
        result.centerCash = result.centerCash || 0,
        result.presidentCash = result.presidentCash || 0,
        result.totalCash = result.storeCash + result.centerCash + result.presidentCash;
        result.totalCash = parseFloat(result.totalCash.toFixed(2));
// debugger;
        result.lastWeekCash1 = result.lastWeekCash1||0;
        result.lastWeekCash2 = result.lastWeekCash2||0;
        result.lastWeekCash3 = result.lastWeekCash3||0;

        result.lastUsedWeekCash1 = result.lastUsedWeekCash1||0;
        result.lastUsedWeekCash2 = result.lastUsedWeekCash2||0;
        result.lastUsedWeekCash3 = result.lastUsedWeekCash3||0;

        this.dashboard = result;
        this.loading = false;
      })
    },
    showOutTypeText(){
      let outTypeText ='';
        if(this.type==='2'){
          outTypeText = '订单出库';
        } else if(this.type==='3'){
          outTypeText = '40交接20';
        } else if(this.type==='7'){
          outTypeText = '业务赠送';
        } else if(this.type==='8'){
          outTypeText = '合创出库';
        } else if(this.type==='9'){
          outTypeText = '退换货';
        } else if(this.type==='10'){
          outTypeText = '门票出库';
        }
        return outTypeText;
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.order_count .ant-card-body {
  padding: 0 24px 3px !important;
  display: flex;
  flex-wrap: wrap;
}
.card_title {
    width: 100%;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 22px;
}
.card_content {
  width: 100%;
  font-size: 18px;
  line-height: 30px;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  margin-top: 4px;
  color: #000;
}
.order_count .card_content {
  width: 50%;
}
</style>
